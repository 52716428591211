import React, { useState, Suspense } from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  Link,
  Router,
  Navigate,
  useNavigate,
} from "react-router-dom";
import App from "../../../App";
import "./Login.css";
import axios from "axios";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { CSpinner } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import "../../../scss/style.scss";
import Dashboard from "../../dashboard/Dashboard";
import routes from "../../../routes";
import logoEn from "src/assets/images/webLogoEnLogin.svg";
import logoAr from "src/assets/images/webLogoARLogin.svg";

const Login = () => {
  //variables
  const DefaultLayout = React.lazy(() =>
    import("../../../layout/DefaultLayout")
  );
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleLogin = () => {
    axios
      .post("https://cmsapi.tobsir.com/api/Cms/CMSLogin", {
        lang: "en",
        userName: userName,
        password: password,
      })
      .then((response) => {
        let datatxt = JSON.stringify(response.data);
        let obj = JSON.parse(datatxt);
        let token = obj.data.token;
        let avatar = obj.data.avatar;

        let res = obj.response;
        let userName = obj.data.userName;
        let employeeName = obj.data.employeeName;

        if (response.data.response === 1) {
          setError(null);
          setLoading(true);
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("avatar", avatar);
          sessionStorage.setItem("userName", userName);
          sessionStorage.setItem("employeeName", employeeName);
          sessionStorage.setItem("cmsUserID", obj.data.webEmployeeID);
          sessionStorage.setItem("lang", "en");
          setdata(obj.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("Invalid username or password");
        setShow(true);
        console.error("error", error);
        console.error("error", error);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {!loading ? (
        <CContainer>
          <CRow className="justify-content-center">
            <CCol lg="5" xs="12">
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={handleLogin}>
                      <div style={{ width: "119px", height: "53px" }}>
                        <img
                          src={logoEn}
                          style={{ width: "100%", height: "100%" }}
                        ></img>
                      </div>
                      <p className="text-medium-emphasis pt-3">
                        Sign In to your account
                      </p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          type="text"
                          placeholder="username"
                          value={userName}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </CInputGroup>
                      {show ? (
                        <p className="text-center" style={{ color: "red" }}>
                          {error}
                        </p>
                      ) : (
                        ""
                      )}
                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            color="primary"
                            className="px-4"
                            value={loading ? "loading" : "login"}
                            onClick={handleLogin}
                          >
                            Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      ) : (
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      )}
    </div>
  );
};

export default Login;
