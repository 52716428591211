import React from "react";

const PrivacySabaho = () => {
  return (
    <div style={{ direction: "rtl", padding: "50px" }}>
      <h3>الخصوصية وحقوق الملكية</h3>
      <p
        style={{
          color: "grey",
          width: "50%",
        }}
      >
        <ul>
          <li>test1</li>

          <li>test2</li>
          <li>test3</li>
        </ul>
      </p>
    </div>
  );
};

export default PrivacySabaho;
