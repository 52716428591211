import React from "react";

const AboutSabaho = () => {
  return (
    <div style={{ direction: "rtl", padding: "50px" }}>
      <h3>من نحن</h3>
      <p
        style={{
          color: "grey",
          width: "50%",
        }}
      >
        تبصير”Sabaho”- هو تطبيق مسلّي وفريد من نوعه يتيح لك فرصة الاستفادة من
        الخدمات التالية: العلاقة بالحبيب، وقراءة الفنجان، وعلم الوجه، وتفسير
        الأحلام، وقراءة الكفّ، علم الفلك و الأبراج، الاستشارة الروحانية، و قراءة
        ورق التّاروت!
      </p>
    </div>
  );
};

export default AboutSabaho;
