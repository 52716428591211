import React from "react";
import BroadcastList from "./views/Report/BroadcastList/BroadcastList";
import ClientSummary from "./views/Report/ClientSummary/ClientSummary";
import AddService from "./views/Services/AddService/AddService";
import { element } from "prop-types";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() =>
  import("./views/theme/typography/Typography")
);
//about sabaho page

const aboutSabaho = React.lazy(() => import("./views/AboutSabaho"));
const privacySabaho = React.lazy(() => import("./views/PrivacySabaho"));
// Base
const addEmployee = React.lazy(() =>
  import("./views/Employees/addEmployee/addEmployee")
);
const addService = React.lazy(() =>
  import("./views/Services/AddService/AddService")
);
const notification = React.lazy(() =>
  import("./views/Notification/AllNotification/notification")
);
const addnotification = React.lazy(() =>
  import("./views/Notification/AddNotification/AddNotifications")
);
const ReadOnlyRaw = React.lazy(() =>
  import("./views/Employees/update/ReadOnlyRaw")
);
const Delete = React.lazy(() => import("./views/Employees/delete/Delete"));
const ViewEmployee = React.lazy(() =>
  import("./views/Employees/View-Employee/View Employee")
);
const Detail = React.lazy(() => import("./views/Employees/detail/detail"));
const ServiceofEmploee = React.lazy(() =>
  import("./views/Employees/Service-of-Employee/ServiceofEmploye")
);
const ServicesEmployee = React.lazy(() =>
  import("./views/Employees/ServiceEmployee/ServicesEmployee")
);
const Navs = React.lazy(() => import("./views/Employees/navs/Navs"));
const Paginations = React.lazy(() =>
  import("./views/Employees/paginations/Paginations")
);
const Placeholders = React.lazy(() =>
  import("./views/Employees/placeholders/Placeholders")
);
const Popovers = React.lazy(() =>
  import("./views/Employees/popovers/Popovers")
);
const Progress = React.lazy(() =>
  import("./views/Employees/progress/Progress")
);
const Spinners = React.lazy(() =>
  import("./views/Employees/spinners/Spinners")
);
const Tables = React.lazy(() => import("./views/Employees/tables/Tables"));
const EditableRaw = React.lazy(() =>
  import("./views/Employees/tooltips/EditableRaw")
);
const ChangePassword = React.lazy(() =>
  import("./views/Employees/changePassword/ChangePassword")
);
const OrderEmployee = React.lazy(() =>
  import("./views/Employees/orderEmployee/OrderEmployee")
);
//Forms
const ViewAllService = React.lazy(() =>
  import("./views/Services/viewallservice/ViewAllService")
);
const FloatingLabels = React.lazy(() =>
  import("./views/Services/floating-labels/FloatingLabels")
);
const InfoService = React.lazy(() =>
  import("./views/Services/infoservice/InfoService")
);
const SabahoHoroscope = React.lazy(() =>
  import("./views/SabahoHoroscope/SabahoHoroscope")
);
const ServiceDetail = React.lazy(() =>
  import("./views/Services/service-detail/Service-Detail")
);
const Layout = React.lazy(() => import("./views/Services/layout/Layout"));
const Range = React.lazy(() => import("./views/Services/range/Range"));
const UpdateService = React.lazy(() =>
  import("./views/Services/update/Update")
);
const Validation = React.lazy(() =>
  import("./views/Services/validation/Validation")
);

const Charts = React.lazy(() => import("./views/charts/Charts"));
const ResetPassword = React.lazy(() =>
  import("./views/Employees/ResetPassword/ResetPassword")
);
// Icons
const CoreUIIcons = React.lazy(() =>
  import("./views/Store/add-offer/Add-offer")
);

const Offers = React.lazy(() => import("./views/Store/offers/Offer"));
const SubscribtionOffers = React.lazy(() =>
  import("./views/Store/SubscriptionOffers/SubscribtionOffers")
);

// Notifications
const ServiceRep = React.lazy(() =>
  import("./views/Report/ServicesReport/ServicesReport")
);
const ClientReqReport = React.lazy(() =>
  import("./views/Report/ClientReqReport/ClientReqReport")
);
const PaymentReqReport = React.lazy(() =>
  import("./views/Report/PaymentReqReport/PaymentReqReport")
);
const WalletReport = React.lazy(() =>
  import("./views/Report/WalletReport/WalletReport")
);
const Ordernfo = React.lazy(() =>
  import("./views/Report/OrderInformation/OrderInformation")
);
const TClientSummary = React.lazy(() =>
  import("./views/Report/ClientSummary/ClientSummary")
);
const BroadastReport = React.lazy(() =>
  import("./views/Report/BroadcastList/BroadcastList")
);
const Widgets = React.lazy(() => import("./views/widgets/Widgets"));
const HoroscopeDetail = React.lazy(() =>
  import("./views/SabahoHoroscope/HoroscopeDetail")
);
const DailyForeCast = React.lazy(() =>
  import("./views/SabahoHoroscope/DailyForecast")
);
const DailyForeCastList = React.lazy(() =>
  import("./views/SabahoHoroscope/DailyForecastList")
);
const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/theme", name: "Theme", element: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", element: Colors },
  { path: "/theme/typography", name: "Typography", element: Typography },
  { path: "/Employees", name: "Employees", element: Detail, exact: true },
  { path: "/Employees/addemployee", name: "addemployee", element: addEmployee },
  { path: "/Employees/update", name: "ReadOnlyRaw", element: ReadOnlyRaw },
  { path: "/Employees/delete", name: "Delete", element: Delete },
  { path: "/SabahoHoroscope", name: "Horoscope", element: SabahoHoroscope },
  {
    path: "/SabahoHoroscope/HoroscopeDetail/:id",
    name: "Detail",
    element: HoroscopeDetail,
  },
  {
    path: "/SabahoHoroscope/HoroscopeDetail/DailyForecast/:id",
    name: "DailyForecast",
    element: DailyForeCast,
  },
  {
    path: "/SabahoHoroscope/HoroscopeDetail/DailyForecastList/:id",
    name: "DailyForecast List",
    element: DailyForeCastList,
  },
  {
    path: "/Notification/notification",
    name: "All Notification",
    element: notification,
  },
  {
    path: "/Notification/AddNotification",
    name: "Add Notification",
    element: addnotification,
  },
  {
    path: "/Employees/View-Employee",
    name: "Detail of Employee",
    element: ViewEmployee,
  },
  {
    path: "/Employees/orderEmployee",
    name: "Order of Employee",
    element: OrderEmployee,
  },
  { path: "/Employees/detail", name: "", element: Detail },
  {
    path: "/Employees/Service-of-Employee/:id",
    name: "Services of Employee",
    element: ServiceofEmploee,
  },
  {
    path: "/Employees/ServiceEmployee",
    name: "Service Employee",
    element: ServicesEmployee,
  },
  {
    path: "/Employees/ResetPassword",
    name: "Reset Password",
    element: ResetPassword,
  },
  { path: "/Employees/navs", name: "Navs", element: Navs },
  { path: "/Employees/paginations", name: "Paginations", element: Paginations },
  {
    path: "/Employees/placeholders",
    name: "Placeholders",
    element: Placeholders,
  },
  { path: "/Employees/popovers", name: "Popovers", element: Popovers },
  { path: "/Employees/progress", name: "Progress", element: Progress },
  { path: "/Employees/spinners", name: "Spinners", element: Spinners },
  { path: "/Employees/tables", name: "Tables", element: Tables },
  { path: "/Employees/tooltips", name: "EditableRaw", element: EditableRaw },
  { path: "/charts", name: "Charts", element: Charts },
  { path: "/Services", name: "Services", element: InfoService, exact: true },
  { path: "/Services/infoservice", name: "", element: InfoService },
  { path: "/Services/update", name: "Update", element: UpdateService },
  { path: "/Services/AddService", name: "AddService", element: AddService },
  {
    path: "/Services/viewallservice",
    name: "",
    element: ViewAllService,
  },
  { path: "/Services/range", name: "Range", element: Range },
  {
    path: "/Services/service-detail",
    name: "Detail of Service",
    element: ServiceDetail,
  },
  {
    path: "/Services/floating-labels",
    name: "Floating Labels",
    element: FloatingLabels,
  },
  { path: "/Services/layout", name: "Layout", element: Layout },
  { path: "/Services/validation", name: "Validation", element: Validation },
  { path: "/Store", exact: true, name: "Store", element: Offers },
  { path: "/Store/add-offer", name: "Add Offer", element: CoreUIIcons },

  { path: "/Store/offers", name: "Offers", element: Offers },
  {
    path: "/Store/SubscriptionOffers",
    name: "Subscription Offers",
    element: SubscribtionOffers,
  },
  {
    path: "/Report",
    name: "Report",
  },
  {
    path: "/Report/ServicesReport",
    name: "Services Report",
    element: ServiceRep,
  },
  {
    path: "/Report/ClientReqReport",
    name: "Clients Requests Report",
    element: ClientReqReport,
  },
  {
    path: "/Report/PaymentReqReport",
    name: "Payments Requests Report",
    element: PaymentReqReport,
  },
  {
    path: "/Report/BroadcastList",
    name: "Payments Requests Report",
    element: BroadcastList,
  },
  {
    path: "/Report/OrderInformation",
    name: "Order Information",
    element: Ordernfo,
  },
  {
    path: "/Report/WalletReport",
    name: "Employee Wallet Report",
    element: WalletReport,
  },
  {
    path: "/Report/ClientSummary",
    name: "Broadcast List",
    element: BroadcastList,
  },
  { path: "/widgets", name: "Widgets", element: Widgets },
  {
    path: "/Admin/changePassword",
    name: "ChangePassword",
    element: ChangePassword,
  },
  {
    path: "/aboutSabaho",
    name: "AboutSabaho",
    element: aboutSabaho,
  },
  {
    path: "/privacySabaho",
    name: "PrivacySabaho",
    element: privacySabaho,
  },
];

export default routes;
