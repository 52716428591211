import { CRow } from "@coreui/react";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Form, FormSelect, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AddService = () => {
  const [title, setTitle] = useState("");
  const [Description, setDescription] = useState("");
  const [TypeID, setTypeID] = useState(0);
  const [Services, setServices] = useState([]);

  let navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const handleAddSabahoService = () => {
    axios
      .post("https://cmsapi.tobsir.com/api/Service/Create", {
        lang: "en",
        cmsUserID: sessionStorage.getItem("cmsUserID"),
        token: token,
        title: title,
        typeID: TypeID,
        description: Description,
        isActive: true,
      })
      .then((response) => {
        console.log(response);
        if (response.data.response == 1) {
          alert("New service has been added successfully");
          navigate(-1);
        } else {
          alert("somthing went wrong please try again");
        }
        if (response.data.response == 13) {
          // setLoad(false);
          alert("You must to log in again");
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    axios
      .post("https://cmsapi.tobsir.com/api/Service/All", {
        Lang: "en",
        cmsUserID: sessionStorage.getItem("cmsUserID"),
        token: token,
      })
      .then((response) => {
        console.log(response);
        let datatxt = JSON.stringify(response.data);
        let obj = JSON.parse(datatxt);
        if (obj.response == 13) {
          alert("You must to log in again");
          navigate("/login");
        } else {
          setServices(obj.data);
        }
      });
  }, []);
  return (
    <div className="p-4">
      <Row>
        <Col>
          {" "}
          <h3 className="title">Add New Service to Sabaho App</h3>
        </Col>
        <Col className="text-end my-3">
          {
            <Button
              style={{
                marginRight: "0px",
                cursor: "pointer",
                backgroundColor: "#1a73e8",
                color: "white",
              }}
              disabled={!title || !Description || TypeID == 0}
              onClick={() => handleAddSabahoService()}
            >
              Add
            </Button>
          }

          <Button
            className="mx-4 btn btn-success"
            style={{
              marginRight: "0px",
              cursor: "pointer",
              border: "solid 1px #1a73e8",
              color: "#1a73e8",
              backgroundColor: "white",
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="d-flex m-auto">
        <Col xs="5">
          <Form.Group controlId="form.Name" className="my-3">
            <Form.Label className="upload-3 fw-bold">Service Name :</Form.Label>
            <Form.Control
              type="text"
              required
              style={{ color: "black" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="d-flex m-auto">
        <Col xs="5">
          <Form.Group controlId="form.Name" className="my-3">
            <Form.Label className="upload-3 fw-bold">Type:</Form.Label>
            <FormSelect
              style={{ marginBottom: "40px" }}
              onChange={(e) => setTypeID(e.target.value)}
            >
              <option value="0">Select Type</option>
              <option value="1">Coffee Reading</option>
              <option value="2">Dream</option>
              <option value="3">Face Reading</option>
              <option value="4">Horoscope</option>
              <option value="5">Palm Reading</option>
              <option value="6">Soulmate</option>
              <option value="7">Spiritual</option>
              <option value="8">Tarot</option>
              <option value="9">Custom Service</option>

              {/* {Services?.filter((s) => s.appPlatformID == 1).map(
                (item, index) => (
                  <option key={index} value={item?.typeID}>
                    {item?.serviceName}
                  </option>
                )
              )} */}
            </FormSelect>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs="10">
          <Form.Group controlId="form.Name" className="my-3">
            <Form.Label className="upload-3 fw-bold">Description :</Form.Label>
            <Form.Control
              type="text"
              required
              as="textarea"
              style={{ color: "black", height: "200px" }}
              value={Description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default AddService;
