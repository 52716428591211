import React, { useState, useEffect, useRef } from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd"
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Link,
  Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Button, FormSelect } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormTextarea,
  CImage,
  CCardGroup,
  CFormCheck,
} from "@coreui/react";
import { Container, Row, Col, Card, Form, ButtonGroup } from "react-bootstrap";
import call from "../../../assets/images/call.png";
import TextTrim from "react-text-trim";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import ReactPaginate from "react-paginate";

const BroadcastList = () => {
  const token = sessionStorage.getItem("token");
  const employeeID = sessionStorage.getItem("employeeID");
  const [employees, setEmployees] = useState([]);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [total, setTotal] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const navigate = useNavigate();
  const handlePageClick = (event) => {
    document.documentElement.scrollTo(0, 0);
    const newOffset = (event.selected * 1) % total;

    setOffset(newOffset);
  };
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = offset + 2;
    console.log(`Loading items from ${offset} to ${endOffset}`);
    setCurrentItems(employees.slice(offset, endOffset));
    setPageCount(Math.ceil(employees.length / 2));
  }, [offset]);
  useEffect(() => {
    setCurrentData(employees);
  }, [offset, employees]);
  useEffect(() => {

    axios
      .post("https://cmsapi.tobsir.com/api/Employees/BroadcastList", {
        Lang: "en",
        cmsUserID: sessionStorage.getItem("cmsUserID"),
        token: token,
        employeeID: employeeID,
        pageSize: 10,
        pageNumber: offset + 1,
      })
      .then((response) => {
        let datatxt = JSON.stringify(response);
        let obj = JSON.parse(datatxt);
        console.log(response)
       setEmployees(obj.data.broadcasts); 
      
      
      });
  
  } 
  , [offset]);
  return (
    <div>
        <h1>Broadcast List</h1>
      { employees.map((employee, index) => {
            return(
                <div>
                <Row className=" mt-3" style={{ border: "1px solid #4d5f75" }}>
                 <Row>
                <Col xs="2">
                  <div
                    className="mt-3"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={employee.avatar}
                    />
                  </div>
                </Col>
                </Row>
                  <Row>
                    <Col xs="9">
                      <div className="d-flex">
                        <p className="fw-bold">Name :</p>{" "}
                        <p style={{ paddingLeft: "25px" }}>
                          {" "}
                          {employee.employeeName}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="9">
                      <div className="d-flex">
                        <p className="fw-bold">Title :</p>{" "}
                        <p style={{ paddingLeft: "25px" }}>
                          {" "}
                          {employee.title}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <p className="fw-bold">Description : </p>
                  <TextTrim
                    refId="TextTrim"
                    text={employee.description}
                    maxLines={3}
                    delimiter="..."
                    fontSize={16}
                    lineHeight={25}
                    containerStyle={{}}
                    textWrapperStyle={{}}
                    buttonStyle={{}}
                  />
                  <Row className="pt-2">
                    <Col xs="6">
                      <div className="d-flex pt-2">
                        <p className="fw-bold"> Start Date : </p>
                        <p style={{ paddingLeft: "25px" }}>
                          {employee.starDtate}
                        </p>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="d-flex pt-2">
                        <p className="fw-bold"> Viewers : </p>
                        <p style={{ paddingLeft: "25px" }}>
                          {employee.viewers}
                        </p>
                      </div>
                    </Col>
                  </Row>
            </Row>
                </div>
            )
        })
      }
        <ReactPaginate
        previousLabel={<GrFormPrevious />}
        nextLabel={<GrFormNext />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName="pagination d-flex justify-content-center"
        activeClassName="paginated-active"
        forcePage={offset}
      ></ReactPaginate>
    </div>
  )
}

export default BroadcastList;