import React, { Component, Suspense } from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  NavLink,
  Link,
} from "react-router-dom";
import "./scss/style.scss";
import AboutSabaho from "./views/AboutSabaho";
import PrivacySabaho from "./views/PrivacySabaho";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Logout = React.lazy(() => import("./views/pages/page500/Logout"));
const response = sessionStorage.getItem("response");

class App extends Component {
  render() {
    return (
      <div className="App">
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/aboutSabaho" element={<AboutSabaho />} />
              <Route path="/privacySabaho" element={<PrivacySabaho />} />
              <Route
                exact
                path="/login"
                name="Login Page"
                element={<Login />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                element={<Register />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Logout />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </div>
    );
  }
}

export default App;
