import React from 'react'

const Dashboard = () => {
  const name=sessionStorage.getItem("employeeName");
  return (
    <>
      <h1>Hello {name}</h1>
    </>
  )
}

export default Dashboard
